<template>
  <div class="app-container">
    <div class="page-title" style="display:flex; flex-direction:row">
      <h2>{{ $t('categorieQuestion.categorieQuestions') }}</h2>
      <h2>📚</h2>
    </div>
    <el-row :gutter="40" class="panel-group">
      <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
        <div class="card-panel">
          <div class="card-panel-icon-wrapper icon-people">
            <h1>📚</h1>
          </div>
          <div class="card-panel-description">
            <div class="card-panel-text">
              categorie Questions
            </div>
            <count-to :start-val="0" :end-val="list.length" :duration="2600" class="card-panel-num" />
          </div>
        </div>
      </el-col>
    </el-row>
    <el-card v-if="checkRole(['admin'])" class="box-card filter-card just-for-admin">
      <div slot="header" class="clearfix">
        <span>{{ $t('general.filter') }}</span>
        <el-button style="float: right; padding: 3px 0" type="text" @click="initSearch">{{ $t('general.init') }}</el-button>
      </div>
      <div class="filter-container">
        <el-radio v-model="activeList" label="desactives" border @change="getSubCategorieQuestionsList('indexdesactive')">{{ $t('general.F_desactives') }}</el-radio>
        <el-radio v-model="activeList" label="disponibles" border @change="handleFilterCategorieQuestions">{{ $t('general.disponibles') }}</el-radio>
        <el-radio v-model="activeList" label="corbeille" class="danger-radio" border @change="getSubCategorieQuestionsList('indexinvisible')">{{ $t('general.corbeille') }}</el-radio>
        <el-switch
          v-model="categoryFor"
          style="display: inline-block; margin-left:10px"
          active-color="#FF6F04"
          inactive-color="#03ADA1"
          active-text="CI"
          inactive-text="Audit"
          @change="setSearchType(categoryFor)"
        />
      </div>
    </el-card>

    <el-card class="box-card">
      <div class="filter-container">
        <el-input v-model="query.keyword" :placeholder="$t('general.keyword')" class="wd-200 mr-15 filter-item" @keyup.enter.native="handleFilterCategorieQuestions" />
        <el-button v-waves class="mr-15 filter-item" type="primary" icon="el-icon-search" @click="handleFilterCategorieQuestions">
          {{ $t('general.search') }}
        </el-button>
        <el-button v-waves :loading="downloading" class="filter-item" type="primary" icon="el-icon-download" @click="handleDownload">
          {{ $t('general.export') }}
        </el-button>
        <el-button v-if="checkPermission(['creer categorieQuestion'])" class="mr-15 filter-item" type="primary" icon="el-icon-plus" @click="handlecreateCategorieQuestion">
          {{ $t('general.add') }}
        </el-button>
        <el-button v-if="checkPermission(['creer categorieQuestion'])" class="mr-15 filter-item" type="primary" icon="el-icon-plus" @click="drawer=true">
          Importer
        </el-button>
      </div>
      <el-table v-loading="loading" :data="list" border fit highlight-current-row style="width: 100%" :row-class-name="tableRowClassName">
        <!-- el-table-column align="center" label="Code" min-width="100px">
          <template slot-scope="scope">
            <span>{{ scope.row.code }}</span>
          </template>
        </el-table-column-->

        <el-table-column align="center" :label="$t('general.libelle')" min-width="200px">
          <template slot-scope="scope">
            <span>{{ scope.row.libelle }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('general.description')" min-width="300px">
          <template slot-scope="scope">
            <span>{{ scope.row.description }}</span>
          </template>
        </el-table-column>

        <el-table-column v-if="checkPermission(['activer categorieQuestion'])" align="center" :label="$t('general.status')" width="100">
          <template slot-scope="scope">
            <el-switch
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.is_active"
              @change="setCategorieQuestionStatus(scope.row.id,'active')"
            />
          </template>
        </el-table-column>

        <el-table-column v-if="checkRole(['admin']) && (activeList=='corbeille' || query.keyword)" align="center" :label="$t('general.restaurer')" width="100">
          <template slot-scope="scope">
            <el-switch
              v-if="!scope.row.is_visible"
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.is_visible"
              @change="setCategorieQuestionStatus(scope.row.id,'visible')"
            />
          </template>
        </el-table-column>

        <el-table-column
          v-if="checkPermission(['modifier categorieQuestion','visible categorieQuestion','supprimer categorieQuestion'])"
          align="center"
          :label="1==1 ? $t('general.actions') : $t('general.suppressionDefinitive')"
          width="200"
          :filters="[{ text: 'CI', value: 'CI' }, { text: 'Audit', value: 'Audit' }]"
          :filter-method="filterTag"
          filter-placement="bottom-end"
        >
          <template slot-scope="scope">
            <el-button-group>
              <el-button v-if="scope.row.is_visible && checkPermission(['modifier categorieQuestion'])" type="primary" size="small" icon="el-icon-edit" style="margin-right:10px;" @click="handleEditCategorieQuestion(scope.row.id);" />
              <el-button v-if="checkPermission(['visible categorieQuestion','supprimer categorieQuestion'])" slot="reference" type="danger" size="small" icon="el-icon-delete" style="margin-right:10px;" @click="handleDeleteCategorieQuestion(scope.row.id, scope.row.is_visible, scope.row.libelle);" />
              <el-button type="info" size="small" icon="el-icon-view" @click="showDetailsPage(scope.row.id)" />
              <!-- <el-popover
                placement="bottom"
                title="Autres options"
                width="200"
                trigger="click"
              > -->
              <!-- <el-button size="small" style="margin-left:0px; margin-top:3px" @click="handleDisplayCategoryQuestions(scope.row)">
                  Les questions
                </el-button> -->
              <!-- <el-button size="small" style="margin-left:0px; margin-top:3px" @click="showDetails(scope.row)">
                  Détails
                </el-button>
                <el-button slot="reference" type="info" size="small" icon="el-icon-more" />
              </el-popover> -->
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>

      <pagination v-if="total > 0" :total="total" :page.sync="query.page" :limit.sync="query.limit" @pagination="getCategorieQuestionsList" />

      <!-- ======= REGION USER DIALOG ======= -->
      <el-dialog
        :title="editing ? $t('categorieQuestion.edit') + ' ' + currentCategorieQuestion.libelle : $t('categorieQuestion.AddCategorieQuestion')"
        :visible.sync="dialogCreateCategorieQuestion"
        :before-close="handleCloseCampagneagricoleCreateDraw"
        :loading="categorieQuestionCreating"
        width="30%"
      >
        <div class="content-inputs">
          <el-form ref="categorieQuestionForm" :rules="rules" :model="currentCategorieQuestion" label-position="left">
            <el-form-item :label="$t('categorieQuestion.libelle')" prop="libelle">
              <el-input v-model="currentCategorieQuestion.libelle" />
            </el-form-item>
            <el-form-item :label="$t('general.description')" prop="description">
              <el-input v-model="currentCategorieQuestion.description" type="textarea" />
            </el-form-item>
          </el-form>
          <el-radio-group v-model="ci" size="medium">
            <el-radio-button :label="'audit'">Audit</el-radio-button>
            <el-radio-button :label="'ci'">Contrôle interne</el-radio-button>
          </el-radio-group>
        </div>
        <span slot="footer" class="dialog-footer">
          <div style="text-align:right;">
            <el-button plain type="danger" @click="dialogCreateCategorieQuestion=false">
              {{ $t('permission.cancel') }}
            </el-button>
            <el-button type="primary" :loading="categorieQuestionCreating" @click="editing ? updateCategorieQuestion() : createCategorieQuestion()">
              {{ categorieQuestionCreating ? $t('general.saving') : $t('general.save') }}
            </el-button>
          </div>
        </span>
      </el-dialog>

      <el-dialog
        title="Questions de la catégorie"
        :visible.sync="displayCategoryQuestions"
        width="30%"
      >
        <div class="content-inputs">
          <el-table
            ref="multipleTable"
            :data="categoryQuestions"
            style="width: 100%"
          >
            <!-- el-table-column
              label="Code"
              width="100"
            >
              <template slot-scope="scope">{{ scope.row.code }}</template>
            </el-table-column-->
            <el-table-column
              property="libelle"
              label="Libelle"
              width="auto"
            />
          </el-table>
        </div>
        <span slot="footer" class="dialog-footer">
          <div style="text-align:right;">
            <el-button plain type="info" @click="displayCategoryQuestions=false">
              {{ $t('permission.cancel') }}
            </el-button>
          </div>
        </span>
      </el-dialog>

      <el-drawer
        :title="detailData[0]!== undefined ? 'Détails de la catégorie : '+detailData[0].libelle:'Titre'"
        :visible.sync="detail"
        direction="rtl"
        size="40%"
      >
        <div class="demo-drawer__content">
          <el-table :data="detailData">
            <!-- el-table-column property="code" label="Code" width="150" /-->
            <el-table-column property="libelle" label="Nom" width="200" />
            <el-table-column property="description" label="description" />
          </el-table>

          <div>
            <h4>Les questions de la catégorie : </h4>
          </div>
          <el-table :data="categoryQuestions">
            <!-- el-table-column property="code" label="Code" width="150" /-->
            <el-table-column property="libelle" label="Nom" width="200" />
            <el-table-column property="description" label="description" />
          </el-table>
          <div class="demo-drawer__footer" style="margin-top:'auto'">
            <!-- <el-button type="primary">Imprimer</el-button> -->
          </div>
        </div>
      </el-drawer>
      <el-dialog
        title="Importation"
        :visible.sync="drawer"
        :direction="direction"
        :before-close="handleClose"
        width="60%"
      >
        <upload-excel-component :on-success="handleSuccess" :before-upload="beforeUpload" />
        <el-table :data="tableData" border highlight-current-row style="width: 100%;margin-top:20px;">
          <el-table-column v-for="item of tableHeader" :key="item" :prop="item" :label="item" />
        </el-table>
        <el-button type="success" :disabled="!tableData.length>0" @click="bulkInsert">Insérer</el-button>
      </el-dialog>
      <!-- ======= REGION USER DIALOG ======= -->

    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
import moment from 'moment';
import UploadExcelComponent from '@/components/UploadExcel/index.vue';
import CountTo from 'vue-count-to';
const categorieQuestionResource = new Resource('categorie_questions');
export default {
  name: 'CategorieQuestionsList',
  components: { UploadExcelComponent, Pagination, CountTo },
  directives: { waves, permission, role },
  data() {
    return {
      tableData: [],
      tableHeader: [],
      drawer: false,
      list: null,
      holder: null,
      categoryQuestions: null,
      displayCategoryQuestions: false,
      total: 0,
      loading: true,
      downloading: false,
      detail: false,
      detailData: [],
      activeList: 'disponibles',
      categorieQuestionCreating: false,
      editing: false,
      currentCategorieQuestion: {},
      query: {
        page: 1,
        limit: 100,
        keyword: '',
      },
      ci: '',
      dialogCreateCategorieQuestion: false,
      currentCategorieQuestionId: 0,
      rules: {
        libelle: [{ required: true, message: this.$t('question.LibelleRequired'), trigger: 'blur' }],
      },
      fileList: [],
      categoryFor: '',
    };
  },
  computed: {

  },
  created() {
    this.resetNewCategorieQuestion();
    this.getCategorieQuestionsList();
  },
  methods: {
    checkPermission,
    checkRole,
    isEmptyArray(array){
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    tableRowClassName({ row, rowIndex }) {
      // console.log('the row content : ', row);
      if (row.ci) {
        return 'warning-row';
      } else {
        return 'success-row';
      }
      // return '';
    },
    showDetails(row) {
      this.categoryQuestions = row.questions;
      var newArray = [];
      console.log(row);
      newArray.push(row);
      this.detailData = newArray;
      this.detail = true;
    },
    filterTag(value, row) {
      var tagger = row.ci === true ? 'CI' : 'Audit';
      return tagger === value;
    },
    bulkInsert(){
      console.log('LES ENTETES : ', this.tableHeader);
      var validHeaders = this.tableHeader.length === 2 && this.tableHeader[0] === 'libelle' && this.tableHeader[1] === 'description';
      if (validHeaders) {
        for (let index = 0; index < this.tableData.length; index++) {
          const element = this.tableData[index];
          console.log('A INSERER : ', element);
          if (element.nom !== '') {
            categorieQuestionResource
              .store(element)
              .then(async(response) => {
                console.log('index ', index, response.data);
              })
              .catch(error => {
                console.log(error);
              })
              .finally(() => {
                this.drawer = false;
                this.tableHeader = [];
                this.tableData = [];
              });
          }
        }
        console.log('ENTETES VALIDE');
      } else {
        this.$message({
          message: 'DONNEES NON CONFORMES',
          type: 'info',
          duration: 5 * 1000,
        });
      }
    },
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: 'Please do not upload files larger than 1m in size.',
        type: 'warning',
      });
      return false;
    },
    handleSuccess({ results, header }) {
      this.tableData = results;
      this.tableHeader = header;
    },
    handleDisplayCategoryQuestions(category){
      this.categoryQuestions = category.questions;
      this.displayCategoryQuestions = true;
    },
    handleCloseCampagneagricoleCreateDraw(done) {
      this.$confirm(this.$t('general.closingWarningText'))
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    cancelcategorieQuestionForm() {
      this.$refs.CampagneagricoleCreationDrawer.closeDrawer();
    },
    async setCategorieQuestionStatus(id, type){
      const data = await categorieQuestionResource.get('toogle_' + type + '/' + id);
      if (data.success){
        this.$message({
          type: 'success',
          message: this.$t('general.statusUpdated'),
        });
        this.initSearch();
      } else {
        this.$message({
          type: 'danger',
          message: this.$t('general.statusUpdateError'),
        });
      }
    },
    initSearch(){
      this.handleFilterCategorieQuestions();
    },
    showDetailsPage(id){
      this.$router.push({ path: '/categorieQuestions/' + id });
    },
    async getCategorieQuestionsList() {
      this.loading = true;
      const { data, meta } = await categorieQuestionResource.list(this.query);
      this.list = data.reverse();
      this.holder = data.reverse();
      this.total = meta !== undefined ? meta.total : 0;
      console.log('===== EDI');
      console.log(data);
      console.log('===== EVO');
      this.loading = false;
    },
    async getSubCategorieQuestionsList(subUrl) {
      this.loading = true;
      const { data } = await categorieQuestionResource.sublist('/' + subUrl, this.query);
      this.list = data;
      this.loading = false;
    },
    handleFilterCategorieQuestions() {
      this.query.page = 1;
      this.getCategorieQuestionsList();
      this.categoryFor = '';
    },
    handlecreateCategorieQuestion() {
      this.editing = false;
      this.resetNewCategorieQuestion();
      this.dialogCreateCategorieQuestion = true;
      this.$nextTick(() => {
        this.$refs['categorieQuestionForm'].clearValidate();
      });
    },
    handleEditCategorieQuestion(id){
      this.editing = true;
      this.resetNewCategorieQuestion();
      this.currentCategorieQuestion = this.list.find(abonne => abonne.id === id);
      this.dialogCreateCategorieQuestion = true;
      this.$nextTick(() => {
        this.$refs['categorieQuestionForm'].clearValidate();
      });
    },
    setSearchType(valeur){
      var ab = this.holder;
      ab = ab.filter(v => v.ci === valeur);
      this.list = ab;
      console.log('la tableau sortant : ', ab);
    },
    handleDeleteCategorieQuestion(id, visible, libelle) {
      console.log('id : ' + id + 'libelle : ' + libelle);
      this.$confirm(this.$t('categorieQuestion.deleteWarn') + ' ' + libelle + '. ' + this.$t('general.deleteContinue'), 'Warning', {
        confirmButtonText: 'Oui',
        cancelButtonText: this.$t('general.cancel'),
        type: 'warning',
      }).then(() => {
        console.log('result : ');
        if (!visible && (checkRole(['admin']) || checkPermission(['supprimer categorieQuestion']))) {
          console.log('result 2 : ');
          this.$swal({
            title: this.$t('general.SureQuestion'),
            text: this.$t('general.irrversibleMessage'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$t('general.ouiSupprimer'),
          }).then((result) => {
            if (result.isConfirmed) {
              categorieQuestionResource.destroy(id).then(response => {
                this.$message({
                  type: 'success',
                  message: this.$t('categorieQuestion.suppressionEffectue'),
                });
                this.initSearch();
              }).catch(error => {
                console.log(error);
              });
            }
          });
        } else {
          this.setCategorieQuestionStatus(id, 'visible');
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('general.suppressionAnnule'),
        });
      });
    },
    createCategorieQuestion() {
      this.$refs['categorieQuestionForm'].validate((valid) => {
        if (valid && this.ci.length > 0) {
          this.categorieQuestionCreating = true;
          this.currentCategorieQuestion.ci = this.ci !== 'audit';
          categorieQuestionResource
            .store(this.currentCategorieQuestion)
            .then(async(response) => {
              this.$message({
                message: this.$t('categorieQuestion.NewCategorieQuestion') + ' ' + this.currentCategorieQuestion.libelle + ' ' + this.$t('categorieQuestion.iscreatedSuccessfully'),
                type: 'success',
                duration: 5 * 1000,
              });
              this.currentCategorieQuestionId = response.data.id;
              this.resetNewCategorieQuestion();
              this.dialogCreateCategorieQuestion = false;
              this.handleFilterCategorieQuestions();
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.categorieQuestionCreating = false;
            });
        } else {
          this.$message({
            type: 'info',
            message: this.$t('general.formInvalid'),
          });
          return false;
        }
      });
    },
    updateCategorieQuestion() {
      this.$refs['categorieQuestionForm'].validate((valid) => {
        if (valid) {
          this.categorieQuestionCreating = true;
          categorieQuestionResource.update(this.currentCategorieQuestion.id, this.currentCategorieQuestion).then(response => {
            this.resetNewCategorieQuestion();
            this.handleFilterCategorieQuestions();
            this.$message({
              message: this.$t('categorieQuestion.isupdatedSuccessfully'),
              type: 'success',
              duration: 5 * 1000,
            });
            this.dialogCreateCategorieQuestion = false;
            this.editing = false;
          })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.categorieQuestionCreating = false;
            });
        } else {
          console.log('Erreur !!');
          return false;
        }
      });
    },
    resetNewCategorieQuestion() {
      this.currentCategorieQuestion = {
        libelle: '',
        description: '',
      };
    },
    handleDownload() {
      this.downloading = true;
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['ID', this.$t('categorieQuestion.libelle'), this.$t('general.description')];
        const filterVal = ['id', 'libelle', 'description'];
        const data = this.formatJson(filterVal, this.list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'Liste des categorieQuestions SCIAGRI_' + moment().format('DD-MM-YYYY_hh-mm'),
        });
        this.downloading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    onChange(file, fileList){
      var a = this.fileList;
      a.push({ name: file.name, url: file.url });
      // console.log('onChange begin');
      this.fileList = a;
      // console.log(this.fileList);
      // console.log('onChange end');
    },
  },
};
</script>

<style lang="scss">
.panel-group {
  margin-top: 18px;

  .card-panel-col {
    margin-bottom: 32px;
  }

  .card-panel {
    height: 108px;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    overflow: hidden;
    color: #666;
    background: #fff;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, .05);
    border-color: rgba(0, 0, 0, .05);

    &:hover {
      .card-panel-icon-wrapper {
        color: #fff;
      }

      .icon-people {
        background: #40c9c6;
      }

      .icon-message {
        background: #36a3f7;
      }

      .icon-money {
        background: #f4516c;
      }

      .icon-shopping {
        background: #34bfa3
      }
    }

    .icon-people {
      color: #40c9c6;
    }

    .icon-message {
      color: #36a3f7;
    }

    .icon-money {
      color: #f4516c;
    }

    .icon-shopping {
      color: #34bfa3
    }

    .card-panel-icon-wrapper {
      float: left;
      margin: 14px 0 0 14px;
      padding: 16px;
      transition: all 0.38s ease-out;
      border-radius: 6px;
    }

    .card-panel-icon {
      float: left;
      font-size: 48px;
    }

    .card-panel-description {
      float: right;
      font-weight: bold;
      margin: 26px;
      margin-left: 0px;

      .card-panel-text {
        line-height: 18px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 16px;
        margin-bottom: 12px;
      }

      .card-panel-num {
        font-size: 20px;
      }
    }
  }
}

@media (max-width:550px) {
  .card-panel-description {
    display: none;
  }

  .card-panel-icon-wrapper {
    float: none !important;
    width: 100%;
    height: 100%;
    margin: 0 !important;

    .svg-icon {
      display: block;
      margin: 14px auto !important;
      float: none !important;
    }
  }
}
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}
</style>
